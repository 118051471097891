import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'

import  AppBreadcrumb  from './AppBreadcrumb'
import  AppHeaderDropdown  from './AppHeaderDropdown'
import  logo  from '../../../../../src/assets/images/logo.png'
import axios from 'axios';
import { getAccessToken } from '../../../../Helpers';
import { endPoints } from '../../../../Constants';
import { encryptData } from '../../../../util';

const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const navigate = useNavigate();
  useEffect(()=>{
     //getOpportunityClassAccessRights();
  },[]);

  /*-------------------- get access rights ------------------*/
     const getOpportunityClassAccessRights = async () => {
              console.log(`Hello User`);
              /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            
            await axios.get(`${endPoints}userOpportunityClassAccessRights`,config)
            .then((response)=>{
              
                 const {aUserAccessRights} = response?.data;
                 const salt = process.env.REACT_APP_API_KEY;
                 const sEncryptedData = !!aUserAccessRights ? encryptData({sUserAccessRights:aUserAccessRights}, salt) : '';
                 !!sEncryptedData ? localStorage.setItem("opportunityClassAccessRights", sEncryptedData) : localStorage.removeItem("opportunityClassAccessRights");
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
     }
  /*-------------------- get access rights ------------------*/
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid className='mobileHeaderView'>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          <CImage fluid src={logo} />
        </CHeaderBrand>
        {/* <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        {/* <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderNav className="">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
