
import {decryptData} from './util';

export const getYear = () => {
   let date = new Date();
   return date.getFullYear();
}

export const writeDocumentTitle = (title) => {
      document.title = title;
}
export const getAccessToken = () => {
      let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
      let salt = process.env.REACT_APP_API_KEY;
      let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
      if(!!oIsAdminLoggedIn && !!oIsAdminLoggedIn['token']) {
            return oIsAdminLoggedIn['token'];
      }
      return '';
      
}
export const getLoggedInUserOpportunityClassRights = () => {
      let sOpportunityClassAccessRights = localStorage.getItem('opportunityClassAccessRights');
      let salt = process.env.REACT_APP_API_KEY;
      let oOpportunityClassAccessRights = !!sOpportunityClassAccessRights ? decryptData(sOpportunityClassAccessRights,salt) : '';
      console.log({oOpportunityClassAccessRights});
      if(!!oOpportunityClassAccessRights && !!oOpportunityClassAccessRights['sUserAccessRights']) {
            return oOpportunityClassAccessRights['sUserAccessRights']?.split(',') || [];
      }
      return '';
      
}
export const getLoggedInUserRole = () => {
      let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
      let salt = process.env.REACT_APP_API_KEY;
      let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
      if(!!oIsAdminLoggedIn && !!oIsAdminLoggedIn['user_role']) {
            return oIsAdminLoggedIn['user_role'];
      }
      return '';
      
}
export const getLoggedInUserId = () => {
      let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
      let salt = process.env.REACT_APP_API_KEY;
      let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
      if(!!oIsAdminLoggedIn && !!oIsAdminLoggedIn['id']) {
            return oIsAdminLoggedIn['id'];
      }
      return '';
      
}
export const getLoggedInUserName = () => {
      let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
      let salt = process.env.REACT_APP_API_KEY;
      let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
      if(!!oIsAdminLoggedIn && !!oIsAdminLoggedIn['user_role']) {
            return `${oIsAdminLoggedIn['first_name']} ${oIsAdminLoggedIn['last_name']}`;
      }
      return '';
      
}
export const getLoggedInUserNameWithRole = () => {
      let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
      let salt = process.env.REACT_APP_API_KEY;
      let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
      if(!!oIsAdminLoggedIn && !!oIsAdminLoggedIn['user_role']) {
            return `${oIsAdminLoggedIn['first_name']} ${oIsAdminLoggedIn['last_name']} (${oIsAdminLoggedIn['user_role'].charAt(0).toUpperCase()+ oIsAdminLoggedIn['user_role'].slice(1)})`;
      }
      return '';
      
}
export const getLoggedInUserDetail = (sloggedInUserEncryptedData) => {
         if(!!sloggedInUserEncryptedData) {
            let salt = process.env.REACT_APP_API_KEY;
            return decryptData(sloggedInUserEncryptedData,salt);
         } 
         return false;
}