import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter, CTooltip } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { endPoints } from '../../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../../Helpers'

const Add = (props) => {
      const [inputValues, setInputValue] = useState({
            root_cause_required:'',
            name:'',
            assign_to:'',
            status:'1'
      });
      const [validation, setValidation] = useState({
            root_cause_required:'',
            name:'',
            assign_to:'',
            status:'1'
      });

      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      const [selectedRootCause, setSelectedRootCause] = useState('');
      const [ usersList, setUsersList ] = useState([]);
      const [ selectedAssignUser, setSelectedAssignUser ] = useState([]);

      const navigate = useNavigate();
      const handleCheckboxChange = (value) => {
          setSelectedRootCause(value);
          setInputValue({ ...inputValues, root_cause_required: value });
      };
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      useEffect(()=>{
         
          writeDocumentTitle(props.title);
          getUserList();
      },[]);

      /*---------------- get user list ---------------------*/
      const getUserList = async () => {
        let token = getAccessToken();
          if(!!token) {
              const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
         await axios.get(`${endPoints}adminUsersList`, config)
          .then((response) => {
            if (response.data.status === true) {
                setUsersList(response.data.aUserLists);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
            }
          });
        } else {
            navigate(`${process.env.PUBLIC_URL}/`);
        }
      }
      
    /*---------------- get user list ---------------------*/
      
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              if(!inputValues.name.trim()) {
                errors.name = "Opportunity class name is required";
              } 
              if(!inputValues.root_cause_required) {
                errors.root_cause_required = "Root cause is required";
              }
              return errors;
          };
    
  /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                  axios.post(`${endPoints}add-opportunity-class`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setInputValue({
                            name:'',
                            status:'1'
                        });
                        setSelectedRootCause('');
                        setSelectedAssignUser([]);
                        // setTimeout(()=>{
                        //   navigate(`${process.env.PUBLIC_URL}/admin/users`);
                        // },1500);
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/

  const assignedReportToUser = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedAssignUser(selectedValues);
    setInputValue({ ...inputValues, assign_to: selectedValues });
  }
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={8} ><h5 className="cardHeading">New Opportunity Class</h5></CCol>
                  <CCol xs={4} className="text-end">
                      <NavLink to={`${process.env.PUBLIC_URL}/admin/master/opportunity-classes`} className="linkText"><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                  </CCol>
              </CRow>
                
              </CCardHeader>
              <CCardBody>
               
                  <CForm onSubmit={handleSubmit}>
                   <CRow>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="name">Opportunity Class Name<span className="formError">*</span></CFormLabel>
                              <CFormInput
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Opportunity Class Name"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.name}
                              />
                              <p className="formError">{validation.name ? validation.name :  ''}</p>
                            </div>
                        </CCol>
                        
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                                 <CFormLabel htmlFor="status">Root Cause Required<span className="formError">*</span></CFormLabel>
                               <div>
                                  <CFormCheck inline id="yes" name="root_cause_required" value="yes" label="Yes" checked={selectedRootCause === 'yes'}  onChange={() => handleCheckboxChange('yes')}  />
                                  <CFormCheck inline id="no" name="root_cause_required" value="no" label="No" checked={selectedRootCause === 'no'} onChange={() => handleCheckboxChange('no')}  />
                                </div>
                                <p className="formError">{validation.root_cause_required ? validation.root_cause_required :  ''}</p>
                            </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>

                            <div className="mb-3">
                              <CFormLabel htmlFor="assign_to">Access Rights</CFormLabel>
                              <CTooltip
                                content="Press CTRL to select multiple"
                                placement="top"
                              >
                                <CFormSelect htmlSize={5} name="assign_to" id="assign_to" value={selectedAssignUser} onChange={(e) => assignedReportToUser(e)} multiple aria-label="size 3 select example">
                                  {
                                    usersList && usersList.length > 0 ? usersList.map((item, index) => <option key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>) : null
                                  }
                                </CFormSelect>  
                              </CTooltip>
                              <p className="formError">{validation.assign_to ? validation.assign_to : ''}</p>
                            </div>

                          </CCol>
                        
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="status">Status<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" name="status" id="status" onChange={(e) => handleChange(e)}>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                              </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    
                    <CFooter className='cardFooter'>
                         <CButton type="submit" color="success">Submit</CButton>
                    </CFooter>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default Add
